import { marginMapper } from "@utils";
import { graphql } from "gatsby";
import React, { ReactElement } from "react";
import Helmet from "react-helmet";

interface Props {
    data: unknown;
}

const BoxLayout = ({
    data: { sitecoreLayoutPage: data },
}: Props): ReactElement => {
    data = JSON.parse(data.internal.content);
    // TODO: Temporary fix value for the Sitecore Placeholders. Upcoming
    // cleanup fix will be done in Sitecore.
    const projectName = "brugle";
    // map components
    let components;
    data.sitecore.route.placeholders[`${projectName}-content`].map(
        ({ componentName }) => {
            let component;

            try {
                // Try fetching the actual container for the Layout Service data
                /* eslint-disable-next-line */
                component = require(`../containers/${componentName}`).default;
            } catch (error) {
                // Fetch a temporary placeholder container
                /* eslint-disable-next-line */
                component = require(`../containers/NoContainer`).default;
            }

            components = {
                ...components,
                [componentName]: component,
            };
        },
    );

    return (
        <div>
            <Helmet>
                {/* TODO: Temporary fix. Get from site name later. */}
                <title>Twinbru</title>
            </Helmet>

            {data.sitecore.route.placeholders[`${projectName}-content`].map(
                ({ dataSource, componentName, fields, params, uid }) => {
                    const CustomComponent = components[componentName];

                    return (
                        <div key={uid} style={marginMapper(params)}>
                            <CustomComponent
                                id={dataSource?.replace(/[{}]/g, "")}
                                componentName={componentName}
                                fields={fields}
                            />
                        </div>
                    );
                },
            )}
        </div>
    );
};

export default BoxLayout;

export const query = graphql`
    query ($id: String!) {
        sitecoreLayoutPage(id: { eq: $id }) {
            internal {
                content
            }
        }
    }
`;
